// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts,import_extension=none"
// @generated from file entities/v1alpha/entities.proto (package com.seed.entities.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Change, DeleteEntityRequest, DiscoverEntityRequest, DiscoverEntityResponse, EntityTimeline, GetChangeRequest, GetEntityTimelineRequest, ListDeletedEntitiesRequest, ListDeletedEntitiesResponse, ListEntityMentionsRequest, ListEntityMentionsResponse, SearchEntitiesRequest, SearchEntitiesResponse, UndeleteEntityRequest } from "./entities_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * Provides functionality to query information about Hypermedia Entities.
 *
 * @generated from service com.seed.entities.v1alpha.Entities
 */
export const Entities = {
  typeName: "com.seed.entities.v1alpha.Entities",
  methods: {
    /**
     * Gets a change by ID.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.GetChange
     */
    getChange: {
      name: "GetChange",
      I: GetChangeRequest,
      O: Change,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the DAG of changes for an entity.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.GetEntityTimeline
     */
    getEntityTimeline: {
      name: "GetEntityTimeline",
      I: GetEntityTimelineRequest,
      O: EntityTimeline,
      kind: MethodKind.Unary,
    },
    /**
     * Triggers a best-effort discovery of an entity.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.DiscoverEntity
     */
    discoverEntity: {
      name: "DiscoverEntity",
      I: DiscoverEntityRequest,
      O: DiscoverEntityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Finds the list of local entities whose titles match the input string.
     * A fuzzy search is performed among documents, groups and accounts.
     * For groups and documents, we match the title, while we match alias in accounts.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.SearchEntities
     */
    searchEntities: {
      name: "SearchEntities",
      I: SearchEntitiesRequest,
      O: SearchEntitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes an entity from the local node. It removes all the patches corresponding to it, including comments.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.DeleteEntity
     */
    deleteEntity: {
      name: "DeleteEntity",
      I: DeleteEntityRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Lists deleted entities.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.ListDeletedEntities
     */
    listDeletedEntities: {
      name: "ListDeletedEntities",
      I: ListDeletedEntitiesRequest,
      O: ListDeletedEntitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Undo the entity delition by removing the entity from the deleted list. That entity, if available
     * will be synced back in the next syncing round (or manually discovered).
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.UndeleteEntity
     */
    undeleteEntity: {
      name: "UndeleteEntity",
      I: UndeleteEntityRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * List mentions of a given Entity across the locally-available content.
     *
     * @generated from rpc com.seed.entities.v1alpha.Entities.ListEntityMentions
     */
    listEntityMentions: {
      name: "ListEntityMentions",
      I: ListEntityMentionsRequest,
      O: ListEntityMentionsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

