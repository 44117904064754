// @generated by protoc-gen-es v1.4.1 with parameter "target=ts,import_extension=none"
// @generated from file documents/v3alpha/comments.proto (package com.seed.documents.v3alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { BlockNode } from "./documents_pb";

/**
 * Request to create a comment.
 *
 * @generated from message com.seed.documents.v3alpha.CreateCommentRequest
 */
export class CreateCommentRequest extends Message<CreateCommentRequest> {
  /**
   * Requred. Account ID to which the comment is applied.
   *
   * @generated from field: string target_account = 1;
   */
  targetAccount = "";

  /**
   * Required. Path within the account where the comment is applied.
   *
   * @generated from field: string target_path = 2;
   */
  targetPath = "";

  /**
   * Required. Version of the document at the time of the comment.
   *
   * @generated from field: string target_version = 3;
   */
  targetVersion = "";

  /**
   * Optional. When current comment is a reply to another comment,
   * this must be the ID of the comment being replied to.
   * Account and path of the parent comment must be the same as the current comment.
   *
   * @generated from field: string reply_parent = 4;
   */
  replyParent = "";

  /**
   * Required. Content of the comment.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.BlockNode content = 5;
   */
  content: BlockNode[] = [];

  /**
   * Required. Name of the key to use for signing the comment.
   *
   * @generated from field: string signing_key_name = 6;
   */
  signingKeyName = "";

  /**
   * Optional. ID of the capability that allows publishing comments for the target account and path.
   * Anyone can create comments to anything, but having a capability to comment makes sure your comments are propagated along with the content.
   *
   * @generated from field: string capability = 7;
   */
  capability = "";

  constructor(data?: PartialMessage<CreateCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.CreateCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "reply_parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "content", kind: "message", T: BlockNode, repeated: true },
    { no: 6, name: "signing_key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "capability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined, b: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined): boolean {
    return proto3.util.equals(CreateCommentRequest, a, b);
  }
}

/**
 * Request to get a comment.
 *
 * @generated from message com.seed.documents.v3alpha.GetCommentRequest
 */
export class GetCommentRequest extends Message<GetCommentRequest> {
  /**
   * Required. ID of the comment to retrieve.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.GetCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommentRequest {
    return new GetCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommentRequest {
    return new GetCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommentRequest {
    return new GetCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommentRequest | PlainMessage<GetCommentRequest> | undefined, b: GetCommentRequest | PlainMessage<GetCommentRequest> | undefined): boolean {
    return proto3.util.equals(GetCommentRequest, a, b);
  }
}

/**
 * Request to list comments.
 *
 * @generated from message com.seed.documents.v3alpha.ListCommentsRequest
 */
export class ListCommentsRequest extends Message<ListCommentsRequest> {
  /**
   * Required. Account ID to list the comments for.
   *
   * @generated from field: string target_account = 1;
   */
  targetAccount = "";

  /**
   * Required. Path within the account to list the comments for.
   *
   * @generated from field: string target_path = 2;
   */
  targetPath = "";

  /**
   * Optional. The maximum number of comments to return.
   *
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * Optional. The page token obtained from a previous request (if any).
   *
   * @generated from field: string page_token = 4;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListCommentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListCommentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCommentsRequest {
    return new ListCommentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCommentsRequest {
    return new ListCommentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCommentsRequest {
    return new ListCommentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCommentsRequest | PlainMessage<ListCommentsRequest> | undefined, b: ListCommentsRequest | PlainMessage<ListCommentsRequest> | undefined): boolean {
    return proto3.util.equals(ListCommentsRequest, a, b);
  }
}

/**
 * Response with a list of comments.
 *
 * @generated from message com.seed.documents.v3alpha.ListCommentsResponse
 */
export class ListCommentsResponse extends Message<ListCommentsResponse> {
  /**
   * List of comments.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.Comment comments = 1;
   */
  comments: Comment[] = [];

  /**
   * Token to retrieve the next page of comments (if necessary).
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListCommentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListCommentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comments", kind: "message", T: Comment, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCommentsResponse {
    return new ListCommentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCommentsResponse {
    return new ListCommentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCommentsResponse {
    return new ListCommentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCommentsResponse | PlainMessage<ListCommentsResponse> | undefined, b: ListCommentsResponse | PlainMessage<ListCommentsResponse> | undefined): boolean {
    return proto3.util.equals(ListCommentsResponse, a, b);
  }
}

/**
 * Comment is a unit of discussion.
 *
 * @generated from message com.seed.documents.v3alpha.Comment
 */
export class Comment extends Message<Comment> {
  /**
   * ID of the current comment.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Account ID that this comment targets.
   *
   * @generated from field: string target_account = 2;
   */
  targetAccount = "";

  /**
   * Path within the account this comment targets.
   *
   * @generated from field: string target_path = 3;
   */
  targetPath = "";

  /**
   * Version of the document this comment targets.
   *
   * @generated from field: string target_version = 4;
   */
  targetVersion = "";

  /**
   * Optional. The ID of the top-level non-reply comment of the conversation thread.
   *
   * @generated from field: string thread_root = 5;
   */
  threadRoot = "";

  /**
   * Optional. The ID of the comment to which this comment is a direct reply.
   * For initial comments this field is empty.
   *
   * @generated from field: string reply_parent = 6;
   */
  replyParent = "";

  /**
   * Account ID of the author of the comment.
   *
   * @generated from field: string author = 7;
   */
  author = "";

  /**
   * Content of the comment.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.BlockNode content = 8;
   */
  content: BlockNode[] = [];

  /**
   * Timestamp when the comment was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 9;
   */
  createTime?: Timestamp;

  /**
   * Optional. ID of the capability this comment was created with, if any.
   *
   * @generated from field: string capability = 10;
   */
  capability = "";

  constructor(data?: PartialMessage<Comment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Comment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "target_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "thread_root", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "reply_parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "content", kind: "message", T: BlockNode, repeated: true },
    { no: 9, name: "create_time", kind: "message", T: Timestamp },
    { no: 10, name: "capability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Comment {
    return new Comment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJsonString(jsonString, options);
  }

  static equals(a: Comment | PlainMessage<Comment> | undefined, b: Comment | PlainMessage<Comment> | undefined): boolean {
    return proto3.util.equals(Comment, a, b);
  }
}

