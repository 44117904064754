// @generated by protoc-gen-es v1.4.1 with parameter "target=ts,import_extension=none"
// @generated from file documents/v3alpha/access_control.proto (package com.seed.documents.v3alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum com.seed.documents.v3alpha.Role
 */
export enum Role {
  /**
   * Invalid default value.
   *
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  ROLE_UNSPECIFIED = 0,

  /**
   * Has write access to the document
   *
   * @generated from enum value: WRITER = 2;
   */
  WRITER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Role)
proto3.util.setEnumType(Role, "com.seed.documents.v3alpha.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 2, name: "WRITER" },
]);

/**
 * Request to list capabilities.
 *
 * @generated from message com.seed.documents.v3alpha.ListCapabilitiesRequest
 */
export class ListCapabilitiesRequest extends Message<ListCapabilitiesRequest> {
  /**
   * Required. Account for which to list the capabilities.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path within the account to list the capabilities for.
   * Empty string means root document.
   * String "*" means all documents.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Optional. By default all capabilities that match the path are returned,
   * even if they were issued for some parent path.
   * If this field is true, only capabilities that match the path exactly are returned.
   *
   * @generated from field: bool ignore_inherited = 3;
   */
  ignoreInherited = false;

  /**
   * Optional. Number of capabilities to return in the response.
   *
   * @generated from field: int32 page_size = 4;
   */
  pageSize = 0;

  /**
   * Optional. Page token to continue listing capabilities.
   *
   * @generated from field: string page_token = 5;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListCapabilitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListCapabilitiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ignore_inherited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCapabilitiesRequest {
    return new ListCapabilitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCapabilitiesRequest {
    return new ListCapabilitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCapabilitiesRequest {
    return new ListCapabilitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCapabilitiesRequest | PlainMessage<ListCapabilitiesRequest> | undefined, b: ListCapabilitiesRequest | PlainMessage<ListCapabilitiesRequest> | undefined): boolean {
    return proto3.util.equals(ListCapabilitiesRequest, a, b);
  }
}

/**
 * Response to list capabilities.
 *
 * @generated from message com.seed.documents.v3alpha.ListCapabilitiesResponse
 */
export class ListCapabilitiesResponse extends Message<ListCapabilitiesResponse> {
  /**
   * List of capabilities.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.Capability capabilities = 1;
   */
  capabilities: Capability[] = [];

  /**
   * Token for the next page, if any.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListCapabilitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListCapabilitiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "capabilities", kind: "message", T: Capability, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCapabilitiesResponse {
    return new ListCapabilitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCapabilitiesResponse {
    return new ListCapabilitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCapabilitiesResponse {
    return new ListCapabilitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCapabilitiesResponse | PlainMessage<ListCapabilitiesResponse> | undefined, b: ListCapabilitiesResponse | PlainMessage<ListCapabilitiesResponse> | undefined): boolean {
    return proto3.util.equals(ListCapabilitiesResponse, a, b);
  }
}

/**
 * Request to create a new capability.
 *
 * @generated from message com.seed.documents.v3alpha.CreateCapabilityRequest
 */
export class CreateCapabilityRequest extends Message<CreateCapabilityRequest> {
  /**
   * Required. Name of the key to use for signing the capability.
   *
   * @generated from field: string signing_key_name = 1;
   */
  signingKeyName = "";

  /**
   * Required. Account ID to which this capability is delegated.
   *
   * @generated from field: string delegate = 2;
   */
  delegate = "";

  /**
   * Required. Account ID to which this capability gives access.
   *
   * @generated from field: string account = 3;
   */
  account = "";

  /**
   * Required. Path within the account that this capability grants access to.
   * Empty string means root document.
   *
   * @generated from field: string path = 4;
   */
  path = "";

  /**
   * Required. Role that this capability grants to the delegate.
   * If capability is nested, the role cannot be higher than the role of any ancestor capability.
   *
   * @generated from field: com.seed.documents.v3alpha.Role role = 5;
   */
  role = Role.ROLE_UNSPECIFIED;

  /**
   * Optional. By default capabilities give access to the path recursively.
   * This flag can be used to restrict the capability only to specific path.
   *
   * @generated from field: bool no_recursive = 6;
   */
  noRecursive = false;

  constructor(data?: PartialMessage<CreateCapabilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.CreateCapabilityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signing_key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delegate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 6, name: "no_recursive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCapabilityRequest {
    return new CreateCapabilityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCapabilityRequest {
    return new CreateCapabilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCapabilityRequest {
    return new CreateCapabilityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCapabilityRequest | PlainMessage<CreateCapabilityRequest> | undefined, b: CreateCapabilityRequest | PlainMessage<CreateCapabilityRequest> | undefined): boolean {
    return proto3.util.equals(CreateCapabilityRequest, a, b);
  }
}

/**
 * Request to get a single capability.
 *
 * @generated from message com.seed.documents.v3alpha.GetCapabilityRequest
 */
export class GetCapabilityRequest extends Message<GetCapabilityRequest> {
  /**
   * Required. ID of the capability to get.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetCapabilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.GetCapabilityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCapabilityRequest {
    return new GetCapabilityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCapabilityRequest {
    return new GetCapabilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCapabilityRequest {
    return new GetCapabilityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCapabilityRequest | PlainMessage<GetCapabilityRequest> | undefined, b: GetCapabilityRequest | PlainMessage<GetCapabilityRequest> | undefined): boolean {
    return proto3.util.equals(GetCapabilityRequest, a, b);
  }
}

/**
 * Capability is an unforgeable token that grants access to a specific path within an account.
 *
 * @generated from message com.seed.documents.v3alpha.Capability
 */
export class Capability extends Message<Capability> {
  /**
   * ID of this capability.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * ID of the account that issued the capability.
   *
   * @generated from field: string issuer = 2;
   */
  issuer = "";

  /**
   * ID of the account that the capability is delegated to.
   *
   * @generated from field: string delegate = 3;
   */
  delegate = "";

  /**
   * Account ID that capability grants access to.
   * This is the same as issuer when it's a first-grade capability,
   * but issuer can be different if the capability is delegated further down.
   *
   * @generated from field: string account = 4;
   */
  account = "";

  /**
   * Path within the account which the capability grants access to.
   *
   * @generated from field: string path = 5;
   */
  path = "";

  /**
   * Role that the capability grants to the delegate.
   *
   * @generated from field: com.seed.documents.v3alpha.Role role = 6;
   */
  role = Role.ROLE_UNSPECIFIED;

  /**
   * Normally capabilities are applied recursively (i.e. path + all the subpaths),
   * but it can be limited to only to the exact path match.
   *
   * @generated from field: bool is_exact = 7;
   */
  isExact = false;

  /**
   * Timestamp when this capability was issued.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 8;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<Capability>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Capability";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "issuer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "delegate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 7, name: "is_exact", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Capability {
    return new Capability().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Capability {
    return new Capability().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Capability {
    return new Capability().fromJsonString(jsonString, options);
  }

  static equals(a: Capability | PlainMessage<Capability> | undefined, b: Capability | PlainMessage<Capability> | undefined): boolean {
    return proto3.util.equals(Capability, a, b);
  }
}

