// @generated by protoc-gen-es v1.4.1 with parameter "target=ts,import_extension=none"
// @generated from file payments/v1alpha/wallets.proto (package com.seed.payments.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * Representation of a wallet
 *
 * @generated from message com.seed.payments.v1alpha.Wallet
 */
export class Wallet extends Message<Wallet> {
  /**
   * Unique wallet identificator. Automatically generated. Unique across accounts.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The account this wallet belongs to.
   *
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * Address of the LND node backing up this wallet. In case lndhub, this will be the
   * URL to connect via rest api. In case LND wallet, this will be the gRPC address.
   *
   * @generated from field: string address = 3;
   */
  address = "";

  /**
   * The name of the wallet.
   *
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * The type of the wallet.
   *
   * @generated from field: string type = 5;
   */
  type = "";

  constructor(data?: PartialMessage<Wallet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.Wallet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wallet {
    return new Wallet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wallet {
    return new Wallet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wallet {
    return new Wallet().fromJsonString(jsonString, options);
  }

  static equals(a: Wallet | PlainMessage<Wallet> | undefined, b: Wallet | PlainMessage<Wallet> | undefined): boolean {
    return proto3.util.equals(Wallet, a, b);
  }
}

/**
 * The request to create a wallet.
 *
 * @generated from message com.seed.payments.v1alpha.CreateWalletRequest
 */
export class CreateWalletRequest extends Message<CreateWalletRequest> {
  /**
   * Required. The account we are creating the wallet to.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Name of the wallet to be created.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<CreateWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.CreateWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateWalletRequest {
    return new CreateWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateWalletRequest {
    return new CreateWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateWalletRequest {
    return new CreateWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateWalletRequest | PlainMessage<CreateWalletRequest> | undefined, b: CreateWalletRequest | PlainMessage<CreateWalletRequest> | undefined): boolean {
    return proto3.util.equals(CreateWalletRequest, a, b);
  }
}

/**
 * The request to import a wallet.
 *
 * @generated from message com.seed.payments.v1alpha.ImportWalletRequest
 */
export class ImportWalletRequest extends Message<ImportWalletRequest> {
  /**
   * Required. The credentials to insert the new wallet in the format of
   * <wallet_type>://<alphanumeric_login>:<alphanumeric_password>@https://<domain>
   *
   * @generated from field: string credentials_url = 1;
   */
  credentialsUrl = "";

  /**
   * Required. Account where this wallet will belong to.
   *
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * Required. The wallet name to be displayed.
   *
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<ImportWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.ImportWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportWalletRequest {
    return new ImportWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportWalletRequest {
    return new ImportWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportWalletRequest {
    return new ImportWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportWalletRequest | PlainMessage<ImportWalletRequest> | undefined, b: ImportWalletRequest | PlainMessage<ImportWalletRequest> | undefined): boolean {
    return proto3.util.equals(ImportWalletRequest, a, b);
  }
}

/**
 * Exported credentials. 
 *
 * @generated from message com.seed.payments.v1alpha.ExportWalletResponse
 */
export class ExportWalletResponse extends Message<ExportWalletResponse> {
  /**
   * The credentials url to be used with a compatible 3rd party app.
   *
   * @generated from field: string credentials = 1;
   */
  credentials = "";

  constructor(data?: PartialMessage<ExportWalletResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.ExportWalletResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportWalletResponse {
    return new ExportWalletResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportWalletResponse {
    return new ExportWalletResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportWalletResponse {
    return new ExportWalletResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExportWalletResponse | PlainMessage<ExportWalletResponse> | undefined, b: ExportWalletResponse | PlainMessage<ExportWalletResponse> | undefined): boolean {
    return proto3.util.equals(ExportWalletResponse, a, b);
  }
}

/**
 * The request to get an lndhub wallet.
 *
 * @generated from message com.seed.payments.v1alpha.WalletRequest
 */
export class WalletRequest extends Message<WalletRequest> {
  /**
   * Required. Id of the wallet to operate with
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<WalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.WalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WalletRequest {
    return new WalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WalletRequest {
    return new WalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WalletRequest {
    return new WalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WalletRequest | PlainMessage<WalletRequest> | undefined, b: WalletRequest | PlainMessage<WalletRequest> | undefined): boolean {
    return proto3.util.equals(WalletRequest, a, b);
  }
}

/**
 * The wallet's balance in satohis.
 *
 * @generated from message com.seed.payments.v1alpha.GetWalletBalanceResponse
 */
export class GetWalletBalanceResponse extends Message<GetWalletBalanceResponse> {
  /**
   * The wallet's balance in satohis.
   *
   * @generated from field: uint64 balance = 1;
   */
  balance = protoInt64.zero;

  constructor(data?: PartialMessage<GetWalletBalanceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.GetWalletBalanceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "balance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWalletBalanceResponse {
    return new GetWalletBalanceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWalletBalanceResponse {
    return new GetWalletBalanceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWalletBalanceResponse {
    return new GetWalletBalanceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetWalletBalanceResponse | PlainMessage<GetWalletBalanceResponse> | undefined, b: GetWalletBalanceResponse | PlainMessage<GetWalletBalanceResponse> | undefined): boolean {
    return proto3.util.equals(GetWalletBalanceResponse, a, b);
  }
}

/**
 * The request to list all wallets under a certain account.
 *
 * @generated from message com.seed.payments.v1alpha.ListWalletsRequest
 */
export class ListWalletsRequest extends Message<ListWalletsRequest> {
  /**
   * Required. Account to list wallets from.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  constructor(data?: PartialMessage<ListWalletsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.ListWalletsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWalletsRequest {
    return new ListWalletsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWalletsRequest {
    return new ListWalletsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWalletsRequest {
    return new ListWalletsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListWalletsRequest | PlainMessage<ListWalletsRequest> | undefined, b: ListWalletsRequest | PlainMessage<ListWalletsRequest> | undefined): boolean {
    return proto3.util.equals(ListWalletsRequest, a, b);
  }
}

/**
 * All the wallets under a certain account.
 *
 * @generated from message com.seed.payments.v1alpha.ListWalletsResponse
 */
export class ListWalletsResponse extends Message<ListWalletsResponse> {
  /**
   * Wallets under the account.
   *
   * @generated from field: repeated com.seed.payments.v1alpha.Wallet wallets = 1;
   */
  wallets: Wallet[] = [];

  constructor(data?: PartialMessage<ListWalletsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.ListWalletsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallets", kind: "message", T: Wallet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWalletsResponse {
    return new ListWalletsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWalletsResponse {
    return new ListWalletsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWalletsResponse {
    return new ListWalletsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListWalletsResponse | PlainMessage<ListWalletsResponse> | undefined, b: ListWalletsResponse | PlainMessage<ListWalletsResponse> | undefined): boolean {
    return proto3.util.equals(ListWalletsResponse, a, b);
  }
}

/**
 * The request to update a wallet
 *
 * @generated from message com.seed.payments.v1alpha.UpdateWalletNameRequest
 */
export class UpdateWalletNameRequest extends Message<UpdateWalletNameRequest> {
  /**
   * Required. The wallet id to be modified.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Required. The new wallet's name.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<UpdateWalletNameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.UpdateWalletNameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateWalletNameRequest {
    return new UpdateWalletNameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateWalletNameRequest {
    return new UpdateWalletNameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateWalletNameRequest {
    return new UpdateWalletNameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateWalletNameRequest | PlainMessage<UpdateWalletNameRequest> | undefined, b: UpdateWalletNameRequest | PlainMessage<UpdateWalletNameRequest> | undefined): boolean {
    return proto3.util.equals(UpdateWalletNameRequest, a, b);
  }
}

/**
 * Gets the account's default wallet.
 *
 * @generated from message com.seed.payments.v1alpha.GetDefaultWalletRequest
 */
export class GetDefaultWalletRequest extends Message<GetDefaultWalletRequest> {
  /**
   * @generated from field: string account = 1;
   */
  account = "";

  constructor(data?: PartialMessage<GetDefaultWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.GetDefaultWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDefaultWalletRequest {
    return new GetDefaultWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDefaultWalletRequest {
    return new GetDefaultWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDefaultWalletRequest {
    return new GetDefaultWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDefaultWalletRequest | PlainMessage<GetDefaultWalletRequest> | undefined, b: GetDefaultWalletRequest | PlainMessage<GetDefaultWalletRequest> | undefined): boolean {
    return proto3.util.equals(GetDefaultWalletRequest, a, b);
  }
}

/**
 * Sets the default wallet used for payments.
 *
 * @generated from message com.seed.payments.v1alpha.SetDefaultWalletRequest
 */
export class SetDefaultWalletRequest extends Message<SetDefaultWalletRequest> {
  /**
   * Required. The wallet id to set as default.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Required. The account the provided wallet is going to
   * be the default.
   *
   * @generated from field: string account = 2;
   */
  account = "";

  constructor(data?: PartialMessage<SetDefaultWalletRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.payments.v1alpha.SetDefaultWalletRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetDefaultWalletRequest {
    return new SetDefaultWalletRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetDefaultWalletRequest {
    return new SetDefaultWalletRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetDefaultWalletRequest {
    return new SetDefaultWalletRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetDefaultWalletRequest | PlainMessage<SetDefaultWalletRequest> | undefined, b: SetDefaultWalletRequest | PlainMessage<SetDefaultWalletRequest> | undefined): boolean {
    return proto3.util.equals(SetDefaultWalletRequest, a, b);
  }
}

